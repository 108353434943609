import React from 'react';
import Card from '../Component/Card'; // Import the Card component
import './CardSection.css'; // Import the CSS file for styling the section
import Image1 from '../Images/camera.jpg'
import Image2 from '../Images/lights.jpg'
import Image3 from '../Images/phone.jpg'

const CardSection = () => {
    return (
        <div className="card-section">
            <Card
                image={Image1}
                title="Card Title 1"
                content="This is the content of the first card."
                link="https://example.com/1"
            />
            <Card
                image={Image2}
                title="Card Title 2"
                content="This is the content of the second card."

                link="https://example.com/2"
            />
            <Card
                title="Card Title 3"
                content="This is the content of the third card."
                image={Image3}
                link="https://example.com/3"
            />
        </div>
    );
};

export default CardSection;
