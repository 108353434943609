import React from 'react';
import { useState, useEffect } from 'react';

const MouseTracker = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const handleMouseMove = (event) => {
            setPosition({
                x: event.clientX,
                y: event.clientY,
            });
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return (
        <div>
            <div
                style={{
                    position: 'fixed',
                    top: position.y,
                    left: position.x,
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'blue',
                    borderRadius: '50%',
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    transition: 'all 80ms'

                }}
            />


        </div>
    )
}

export default MouseTracker;

