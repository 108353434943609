import React from 'react'
import videoSrc from '../videos/earth_rotation.mp4';
// import 'react-html5video/dist/styles.css'
import './VideoSection.css'
function VideoSection() {
    return (
        <div className="video-container">
            <video className="video-player" controls>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {/* {title && <h2 className="video-title">{title}</h2>} */}
        </div>
    )
}

export default VideoSection
