// TwoColumnLayout.js
import React from 'react';
import './Section3.css'; // Import the CSS file
import Image from '../Images/hero-content.jpg';

const Section3 = () => {
    return (
        <div className="container">
            <div className="column column-left">
                <img src={Image} alt="Description of image" className="column-image" />

            </div>
            <div className="column column-right">
                <h3>Our Services</h3>
                <h1 >We solve creative delusions efficiently</h1>
                <p >The display employs new techniques and technology to precisely follow the curves of the design, all the way to the elegantly rounded corners.</p>
            </div>
        </div>
    );
};

export default Section3;