import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import './Navbar.css'; // Import CSS for styling

const Navbar = () => {
  return (
    <header className="header">
      <div className="logo">
        <h1>saltifi.com</h1>
      </div>
      <div className='social-icons'>
        <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'><FaFacebook /></a>
        <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'><FaTwitter /></a>
        <a href='https://linkedin.com' target='_blank' rel='noopener noreferrer'><FaLinkedinIn /></a>
      </div>
    </header>
  );
};

export default Navbar;