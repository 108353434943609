import React from 'react'
import './Section4.css'
function Section4() {
    return (
        <div>
            <div className='number-container'>
                <span className='number'>96</span>
                <span className='number'>1022</span>
                <span className='number'>215</span>
                <span className='number'>215</span>


            </div>
            <div className='details-container'>
                <span className='details'>Projects Completed</span>
                <span className='details'>Happy Clients </span>
                <span className='details'>Hours Spents</span>
                <span className='details'>Members</span>



            </div>
        </div>

    )
}

export default Section4
