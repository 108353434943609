
import './App.css';
import Home from './Home/Home';

function App() {
  return (
<>
<Home/>
</>
);
}

export default App;
