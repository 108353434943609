import React from 'react';
import './Card.css'; // Import the CSS file for styling

const Card = ({ title, content, image, link }) => {
    return (
        <div className="card">
            {image && <img src={image} alt={title} className="card-image" />}
            <div className="card-body">
                <h3 className="card-title">{title}</h3>
                <p className="card-content">{content}</p>
                {link && (
                    <a href={link} className="card-link" target="_blank" rel="noopener noreferrer">
                        Learn More
                    </a>
                )}
            </div>
        </div>
    );
};

export default Card;
