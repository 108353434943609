import React from 'react'
import './Section5.css'
// import Image4 from '../Images/backgroundimg.jpg'

function Section5() {
    return (
        <>
            <div className="background-section">

                <div className="overlay">
                    <h1 className="title">Promotional Contact</h1>
                    <h3 className="content">Ready to work together?</h3>
                    <button class="button"><span class="text">Contact us</span></button>
                </div>
            </div>
        </>
    )
}

export default Section5
