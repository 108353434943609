import MouseTracker from '../MouseTracker';
import HeroSection from './HeroSection';
import './Home.css';
import Navbar from './Navbar';
import VideoSection from './VideoSection';
import Section3 from './Section3';
import Section4 from './Section4';
import CardSection from './CardSection';
import Section5 from './Section5';



function Home() {
  return (
    <>

      <Navbar />
      <MouseTracker />
      <HeroSection />
      <VideoSection />
      <Section3 />
      <Section4 />
      <CardSection />
      <Section5 />




    </>
  );
}

export default Home;