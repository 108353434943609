import React from 'react';
import './HeroSection.css'

function HeroSection() {
    return (
        <div className='herosection'>
            <h1 className='title'> We build experiences.</h1>
            <p className='info'>We collaborate with smart and creative people to build awesome WordPress Full Site Editing Themes.</p>
            <div >
                <button class="button1"><span class="text">Get started</span></button>

            </div>
        </div>
    )
}

export default HeroSection
